import React, { useEffect, useState } from "react"
import tw, {css} from "twin.macro"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import breakpoints from "../../styles/breakpoints"
import mq from '../../styles/media-queries'
import useScript from '../../hooks/useScript'

const windowGlobal = typeof window !== 'undefined' && window;

const adSizes = [
	[300, 600]
]

const getAdUnit = (code) => {
	return {
		code,
		mediaTypes: {
			banner: {
				sizes: adSizes
			}
		},
		bids: [
			{
				bidder: 'bidglass',
				params: {
					adUnitId: '56'
				}
			},
			{
				bidder: 'apacdex',
				params: {
					siteId: '246759', // siteId provided by Apacdex
					floorPrice: 0.01, // default is 0.01 if not declared,
				}
			}
		]
	}

}
const Ad = () => {

	const { width } = useWindowDimensions()
	const [visibleUnits, setVisibleUnits] = useState(0)
	const [renderedUnits, setRenderedUnits] = useState(0)
	const minWidth3 = breakpoints.find(bp => bp.xl).xl
	const minWidth2 = breakpoints.find(bp => bp.lg).lg
	const minWidth1 = breakpoints.find(bp => bp.md).md

	const tagStatus = useScript('https://www.googletagservices.com/tag/js/gpt.js')
	const prebidStatus = useScript(`${process.env.GATSBY_SITE_URL}/scripts/prebid7.18.0.js`)

	useEffect(() => {

		if (tagStatus !== 'ready') {
			return
		}

		var googletag = window.googletag || {};
		googletag.cmd = googletag.cmd || [];
		googletag.cmd.push(function () {
			googletag.pubads().disableInitialLoad();
		});

		googletag.cmd.push(function () {
			googletag.destroySlots()
			googletag.defineSlot('/22175459031/fileio-halfpage-gallery-1', adSizes, 'fileio-halfpage-gallery-1').addService(googletag.pubads());
			googletag.defineSlot('/22175459031/fileio-halfpage-gallery-2', adSizes, 'fileio-halfpage-gallery-2').addService(googletag.pubads());
			googletag.defineSlot('/22175459031/fileio-halfpage-gallery-3', adSizes, 'fileio-halfpage-gallery-3').addService(googletag.pubads());
			googletag.pubads().enableSingleRequest();
			googletag.enableServices();
		});

	}, [tagStatus])

	useEffect(() => {

		if (renderedUnits >= visibleUnits || tagStatus !== 'ready' || prebidStatus !=='ready') {
			return
		}

		const PREBID_TIMEOUT = 1000;
		const FAILSAFE_TIMEOUT = 3000;
		const adUnitCodes = []

		for (let u = visibleUnits; u > renderedUnits; u--) {
			adUnitCodes.push(`fileio-halfpage-gallery-${u}`)
		}

		const adUnits = adUnitCodes.map((code) => getAdUnit(code))

		var pbjs = window.pbjs || {};
		pbjs.initAdserverSet = pbjs.initAdserverSet || 0;
		pbjs.que = pbjs.que || [];

		pbjs.que.push(function () {
			pbjs.setConfig({
				// debug: true,
				cache: {
					url: false
				},
				priceGranularity: 'auto',
				userSync: {
					filterSettings: {
						iframe: {
							bidders: ['apacdex'],
							filter: 'include'
						}
					}
				}
			});
			pbjs.removeAdUnit()
			pbjs.addAdUnits(adUnits);
			pbjs.requestBids({
				bidsBackHandler: initAdserver,
				timeout: PREBID_TIMEOUT
			});
		});
	
		function initAdserver(bidResponse) {
			console.log(bidResponse)
			if (pbjs.initAdserverSet >= visibleUnits) return;
			pbjs.initAdserverSet = visibleUnits;
			googletag.cmd.push(function () {
				pbjs.que.push(function () {
					pbjs.setTargetingForGPTAsync();
					const slots = googletag.pubads().getSlots()
					const slotsToRefresh = slots.filter(s => adUnitCodes.indexOf(s.getSlotElementId()) !== -1)
					googletag.pubads().refresh(slotsToRefresh);
				});
			});
		}

		// in case PBJS doesn't load
		setTimeout(function () {
			initAdserver();
		}, FAILSAFE_TIMEOUT);

		setRenderedUnits(visibleUnits)

	}, [visibleUnits, tagStatus, prebidStatus])

	useEffect(() => {
		if (width >= minWidth3) {
			setVisibleUnits(3)
		}
		else if (width >= minWidth2) {
			setVisibleUnits(2)
		}
		else if (width >= minWidth1) {
			setVisibleUnits(1)
		}
		else {
			setVisibleUnits(0)
		}
		console.log('visibleUnits', visibleUnits)
	}, [width])

	return (
		<div tw="absolute w-full h-full hidden md:flex bg-blue pt-14 pr-6 items-center justify-center" css={[
			{
				paddingLeft: "21rem"
			}
		]}>
			<div 
				tw="pointer-events-none flex-1 grid"
				css={[
					{
						[mq.md] : { 
							...tw`grid-cols-1`
						},
						[mq.lg] : { 
							...tw`grid-cols-2`
						},
						[mq.xl] : { 
							...tw`grid-cols-3`
						}

					}
				]}
			>
				<div tw="flex justify-center">
					<div id="fileio-halfpage-gallery-1" tw="w-0 h-0 invisible md:visible bg-black bg-opacity-10" css={[
						{
							[mq.md] : { 
								width:"300px",
								height:"600px"
							}
						}
					]}></div>
				</div>
				<div tw="flex justify-center">
					<div id="fileio-halfpage-gallery-2" tw="w-0 h-0 invisible lg:visible bg-black bg-opacity-10" css={[
						{
							[mq.lg] : { 
								width:"300px",
								height:"600px"
							}
						}
					]}></div>
				</div>
				<div tw="flex justify-center">
					<div id="fileio-halfpage-gallery-3" tw="w-0 h-0 invisible xl:visible bg-black bg-opacity-10" css={[
						{
							[mq.xl] : { 
								width:"300px",
								height:"600px"
							}
						}
					]}></div>
				</div>
			</div>
		</div>
	)
}

export default Ad